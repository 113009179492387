import { useRef } from "react";
import "./App.css";
import copyToClipboard from "./utils/copy";

function ContactEmail() {
  const EMAIL = "hello@noxhit.com";

  const spCopiedRef = useRef(null);

  const onEmailTap = () => {
    copyToClipboard(EMAIL);
    spCopiedRef.current.classList.toggle("visibile");

    setTimeout(() => {
      spCopiedRef.current.classList.toggle("visibile");
    }, 2500);
  };

  return (
    <>
      <span className="links" onClick={onEmailTap}>
        {EMAIL}
      </span>
      <br />
      <span ref={spCopiedRef} className="copied">
        Copied to clipboard.
      </span>
    </>
  );
}

function Link({ text }) {
  const onLinkTap = () => {
    alert("Coming soon.");
  };
  return (
    <span className="links" onClick={onLinkTap}>
      {text}
    </span>
  );
}

function App() {
  return (
    <div className="App">
      <h1>
        NoXhit.<span>!</span>
      </h1>
      <h2>
        We are a bunch of developers fearlessly exploring some of the Good,the Bad and the Ugly ideas. <br />
        We believe every idea deserves a shot.{" "}
      </h2>
      <h2>
        You may have used some of our work <Link text="Recabulary" />, <Link text="ChitGold" />.
      </h2>
      <h2>Occasionally we put our experimental work too in the public domain.</h2>
      <h2>
        If you are using any of that work, be aware that your usage is governed by our <Link text="Terms" /> & <Link text="Privacy" /> policies.
      </h2>
      <h2>
        Have questions, suggestions, or just want to say hello? Reach out to us at <br /> {<ContactEmail />}
      </h2>
      <h2>Stay curious!</h2>
      <h2>Thanks.!</h2>
    </div>
  );
}

export default App;
